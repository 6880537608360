

.feedback{
  border:1px solid $light-gray;
  border-radius:$border-radius-base;
  text-align:center;
  margin-bottom:5px;
  padding:10px 0;
}

.feedback h4{margin:0;}

.feedback span, .feedback label{
  margin:2px 0;
}

.feedback input[type="radio"]{
  margin:0 2px;
  height:16px;
  width:16px;
}

@media (max-width: 768px){
  .feedback{
    padding-bottom:10px;
  }  
}



