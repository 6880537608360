body{
  overflow:hidden;
}


[v-cloak] { display: none; }

.img-icon{
  display:inline-block;
  width:22px !important;
  vertical-align:top;
}

.logo-nav{
	padding: 5px 15px !important;
}

.logo-nav img{
	height:50px;
}

.promo-logo{
  img{
    margin-top:5px;
    height: 69px;
  }
}

.navbar-nav > li > .btn-dark{
  background:#000;
  border-color:#000;
  color:#fff; 
  &:hover, &:focus{
    background-color:rgba(0,0,0,0.6) !important;
    border-color:rgba(0,0,0,0.6);
  }
}

.sidebar{
  background:#4A4A4A;
  .nav-item{
    .nav{
      margin-top: 0;
      .nav-item:first-child{
        border-bottom:1px solid rgba(255,255,255,0.5);
      }
    }
  }
}

.sidebar .nav > li > a:hover, .nav > li > a:focus {
  background-color: rgba(255,255,255,0.2);
  transform: scale(1.05,1.05);
  cursor:pointer;
}

.sidebar .nav > li.active a{
  background:rgba(255,255,255,0.2);
  color:#fff !important;
}

.sidebar-user{
	border-bottom:1px solid rgba(255, 255, 255, 0.3);
	text-align:center;
}

.sidebar-user img{
	display:block;
	margin:15px auto;
	width:64px;
}

.sidebar-user h4{
	margin:15px auto;
}

.sidebar .nav > li img{
  display:inline-block;
  float:left;
  margin-right:5px;
  width:28px;
}

.dropdown-menu > li a {
  color: #66615b;
  font-size: 14px;
  padding: 10px 15px;
  transition: none 0s ease 0s ;
}

.dropdown-menu > li a {
  clear: both;
  color: #333;
  display: block;
  font-weight: 400;
  line-height: 1.42857;
  white-space: nowrap;
}

.dropdown-menu > li  a:hover, .dropdown-menu > li  a:focus {
  background-color: $medium-gray;
  color: rgba(0, 0, 0, 0.9);
  opacity: 1;
  text-decoration: none;
}

.select2-container--bootstrap .select2-selection--single {
  height: 40px !important;
  padding: 8px 24px 8px 12px !important;
}

.bootstrap-switch{
  .bootstrap-switch-label{
    width:78px;
  }
}

.modal-header{
  @include icon-gradient($new-green, $green-color-bottom);
  border-radius: 5px 5px 0 0;
  color:#fff;
}

.modal-title{
  font-weight:bold;
  text-align: center;
}

.card-user img{
	margin:25px auto;
	width:100px;
}

.card-user .author .btn{
  position:absolute; 
  right: 25px; 
  top:10px;
}

.card-course .image{
  margin:0 auto;
  height: 155px;
}

.card-course .user-cover{
  margin:0 auto;
}

.card-course > .content > .author{
  margin-top:0;
  text-align: left;
  padding-bottom: 15px;
}

.course-info{
  border-right:2px solid rgba(0, 0, 0, 0.05);
}

.card > .header{
  border-bottom: 1px solid #ddd;
  padding-bottom:15px;
}

.card > .header img{
  display:inline-block;
  width:40px;
}

.card > .header h4{
  display:inline-block;
  margin:8px 0 0 5px !important;
  vertical-align: top;
}

.card-dashboard > .header{
  border-bottom: 1px solid #ddd;
  float:left;
  padding-bottom:15px;
  width:100%;
}

.card-dashboard > .header img{
  float:left;
  width:40px;
}

.card-dashboard > .header h4 {
  float: left;
  margin: 0px 0 0 5px !important;
  vertical-align: top;
  width: 85%;
  line-height: 24px;
}

.card-dashboard > .header .category{
  float:left;
  margin-left:5px;
  width:60%;
}

.tree-list li {
    list-style-type:none;
    margin:0;
    padding:10px 5px 0 5px;
    position:relative
}

.tree-list li::before, .tree-list li::after {
    content:'';
    left:-20px;
    position:absolute;
    right:auto
}
.tree-list li::before {
    border-left:2px solid #ccc;
    bottom:50px;
    height:100%;
    top:0;
    width:1px
}
.tree-list li::after {
    border-top:2px solid #ccc;
    height:20px;
    top:30px;
    width:25px
}

.tree-list li.parent_li>span:not(.glyphicon) {
    cursor:pointer
}
.tree-list>ul>li::before, .tree-list>ul>li::after {
    border:0
}
.tree-list li:last-child::before {
    height:30px
}
.tree-list li.parent_li>span:not(.glyphicon):hover, .tree-list li.parent_li>span:not(.glyphicon):hover+ul li span:not(.glyphicon) {
    background:#eee;
    border:1px solid #999;
    padding:3px 8px;
    color:#000
}

.tab-pane .table tr:first-child td{
  border-top:0px solid transparent;
}

.select2-container {
  width: 100% !important;
}

.modal#login {
  .control-label{
  color:#636b6f;
  font-weight:300;
  font-size:18px;
  }
  .btn{
    margin:10px 0 15px;
  }
}

.jquery-top-scrollbar{
  position:relative;
  z-index:1;
}

.modal-open .modal {
  z-index:10;
}

.modal-backdrop{
  &.in {
    z-index:8;
  }
}

.btn-group-vertical {
  z-index:8;
}

.el-form--label-top .el-form-item__label {
  padding: 0 0 5px;
  line-height: 16px;
}

.user-photo{
  margin-top:-35px;
}


/*//////  C H A T  //////*/

.chat-window{
    bottom:0;
    position:fixed;
    float:right;
    margin-left:10px;
    right:15px;
}

.chat-window > div > .panel{
    border-radius: 5px 5px 0 0;
}

.msg_container_base{
  background: #e5e5e5;
  margin: 0;
  padding: 0 10px 10px;
  max-height:300px;
  overflow-x:hidden;
}

.top-bar {
  background: #666;
  color: white;
  padding: 10px;
  position: relative;
  overflow: hidden;
}

.msg_receive{
    padding-left:0;
    margin-left:0;
}

.msg_sent{
    padding-bottom:20px !important;
    margin-right:0;
}

.messages {
  background: white;
  padding: 10px;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  max-width:100%;
}

.messages > p {
    font-size: 13px;
    margin: 0 0 0.2rem 0;
  }

.messages > time {
    font-size: 11px;
    color: #ccc;
}

.msg_container {
    padding: 10px;
    overflow: hidden;
    display: flex;
}

.avatar {
    position: relative;
}

.msg_receive:before {
    content: "";
    position: absolute;
    top: 0;
    left: 6px;
    width: 0;
    height: 0;
    border: 6px solid #FFF;
    border-left-color: rgba(0, 0, 0, 0);
    border-bottom-color: rgba(0, 0, 0, 0);
}

.base_sent {
  justify-content: flex-end;
  align-items: flex-end;
}
.msg_sent:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 6px;
    width: 0;
    height: 0;
    border: 6px solid white;
    border-right-color: transparent;
    border-top-color: transparent;
    box-shadow: 1px 1px 2px rgba(black, 0.2); // not quite perfect but close
}

.msg_sent > time{
    float: right;
}

.msg_container_base::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

.msg_container_base::-webkit-scrollbar{
    width: 12px;
    background-color: #F5F5F5;
}

.msg_container_base::-webkit-scrollbar-thumb{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
}

.btn-group.dropup{
    position:fixed;
    left:0px;
    bottom:0;
}

/*//////  E N D  C H A T  //////*/

/*//////  F R O N T E N D  C O U R S E S  //////*/

.dto {
  background:#fff;
  border: 1px solid #87CB16;
  border-radius: 4px;
  color:#87CB16;
  font-size: 11px;
  padding: 5px 15px;
  line-height: 1;
}

.course-price{
  color:#6e6e6e;
  font-size:55px;
  font-weight: 700;
  line-height:1;
}

.course-price span{
  font-size:45px;
}

.course-price small{
  display:inline-block;
  color:#cccccc;
}

.course-price s{
  display:block; 
  font-size:25px;
  text-align:left;
}

.course-price small > span{
  display:block;
  font-size:10px;
  text-align:left;
}

.info-payment{
  padding: 0 15px 0 0;
}

.card-payment h2{
  font-size: 32px;
  font-weight:bold;
  margin:0;
  line-height:1.2;
}

.card-payment thead h2{
  font-family: Arial, Helvetica, sans-serif;
  font-weight:bold;
  font-size:14pt;
  margin:0;
  line-height:1.2;
}

.card-payment p{
  margin:0 0 4px;
  line-height: 1,5;
}

.card-payment-photo{
  background-repeat:no-repeat;
  background-size:cover;
  height:700px;
}

.card-payment hr{
  margin:10px auto;
}

.card-payment tbody{
  font-size:$font-size-medium;
}

.collapsed .arrow .fa-angle-up{
  transition: all .35s ease;
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}
.arrow .fa-angle-up{
  transition: all .35s ease;
  transform: rotate(0deg);
  -ms-transform: rotate(0deg);
}


/*//////  E N D  F R O N T E N D  C O U R S E S  //////*/

@media (max-width: 992px){
  .nav > li > a > img{
    width:28px;
  }
  .nav > li > a > p{
    display:inline-block;
    margin-left:5px;
  }
  .navbar-nav{
    .nav-item{
      .collapse{ display: block;}
    }
  }
  .card-payment-photo{
    background-size: cover;
    background-position:center center;
    height:320px;
  }
  .course-info{
    border-right:0px solid rgba(0, 0, 0, 0.05);
  }
}

@media (max-width: 768px){

  .navbar-nav > li > .btn-dark{
    background:#000 !important;
    border-color:#000;
    color:#fff; 
    &:hover, &:focus{
      background-color:rgba(0,0,0,0.6) !important;
      border-color:rgba(0,0,0,0.6);
    }
  }

  .card-payment-photo{
    height:275px;
  }

  .btn-group{
    margin-top:15px;
    width:100%;
    .btn-wd {
        min-width: 50%;
    }
  }

  .info-payment{
    padding: 15px 15px 0;
  }

}

