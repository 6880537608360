/*      Checkbox and radio         */
.checkbox,
.radio {
  margin-bottom: 12px;
  padding-left: 32px;
  position: relative;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  color: #333333;
}
.checkbox input,
.radio input {
  outline: none !important;
  display: none;
}
.checkbox .icons,
.radio .icons {
  color: $medium-gray;
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
  text-align: center;
  line-height: 21px;
  font-size: 20px;
  cursor: pointer;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}


.checkbox .icons .first-icon,
.radio .icons .first-icon,
.checkbox .icons .second-icon,
.radio .icons .second-icon {
  display: inline-table;
  position: absolute;
  left: 0;
  top: 0;
  background-color: transparent;
  margin: 0;
  @include opacity(1);
}
.checkbox .icons .second-icon,
.radio .icons .second-icon {
  @include opacity(0);
}
.checkbox:hover,
.radio:hover {
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}
.checkbox:hover .first-icon,
.radio:hover .first-icon {
 @include opacity(0);
}
.checkbox:hover .second-icon,
.radio:hover .second-icon {
 @include opacity (1);
}
.checkbox.checked,
.radio.checked {
  color: $info-color;
}
.checkbox.checked .first-icon,
.radio.checked .first-icon {
  opacity: 0;
  filter: alpha(opacity=0);
}
.checkbox.checked .second-icon,
.radio.checked .second-icon {
  opacity: 1;
  filter: alpha(opacity=100);
  color: $info-color;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}
.checkbox.disabled,
.radio.disabled {
  cursor: default;
  color: $medium-gray !important;
}
.checkbox.disabled .icons,
.radio.disabled .icons {
  color: $medium-gray !important;
}
.checkbox.disabled .first-icon,
.radio.disabled .first-icon {
  opacity: 1;
  filter: alpha(opacity=100);
}
.checkbox.disabled .second-icon,
.radio.disabled .second-icon {
  opacity: 0;
  filter: alpha(opacity=0);
}
.checkbox.disabled.checked .icons,
.radio.disabled.checked .icons {
  color: $medium-gray;
}
.checkbox.disabled.checked .first-icon,
.radio.disabled.checked .first-icon {
  opacity: 0;
  filter: alpha(opacity=0);
}
.checkbox.disabled.checked .second-icon,
.radio.disabled.checked .second-icon {
  opacity: 1;
  filter: alpha(opacity=100);
  color: #D9D9D9;
}



/* ============================================================
 * bootstrapSwitch v1.3 by Larentis Mattia @spiritualGuru
 * http://www.larentis.eu/switch/
 * ============================================================
 * Licensed under the Apache License, Version 2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 * ============================================================ */
.has-switch {
    border-radius: 30px;
    cursor: pointer;
    display: inline-block;
    line-height: 1.72222;
    overflow: hidden;
    position: relative;
    text-align: left;
    width: 60px;    
 
   -webkit-user-select: none;  
   -moz-user-select: none;
   -ms-user-select: none;
   -o-user-select: none;
   user-select: none;
     
    /*   this code is for fixing safari bug with hidden overflow for border-radius   */
    -webkit-mask: url('../img/mask.png') 0 0 no-repeat;
    -webkit-mask-size: 60px 24px;
    mask: url('../img/mask.png') 0 0 no-repeat;
}
.has-switch.deactivate {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: default !important;
}
.has-switch.deactivate label,
.has-switch.deactivate span {
  cursor: default !important;
}
.has-switch > div {
   position: relative;
   top: 0;
   width: 100px;
}
.has-switch > div.switch-animate {
  -webkit-transition: left 0.25s ease-out;
  transition: left 0.25s ease-out;
}
.has-switch > div.switch-off {
  left: -35px;
}

.has-switch > div.switch-on {
  left: 0;
}
.has-switch > div label {
  background-color: #FFFFFF;
  @include icon-gradient (rgba(255,255,255,1), rgba(241,241,242,1));
  
  box-shadow: 0 1px 1px #FFFFFF inset, 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.has-switch input[type=checkbox] {
  display: none;
}
.has-switch span {
/*     box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) inset; */
    cursor: pointer;
    float: left;
    font-size: 11px;
    font-weight: 400;
    height: 24px;
    line-height: 15px;
    margin: 0;
    padding-bottom: 6px;
    padding-top: 5px;
    position: relative;
    text-align: center;
    text-indent: -10px;
    width: 50%;
    z-index: 1;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.has-switch span.switch-left {
    background-color: $info-color;
    border-left: 1px solid rgba(0, 0, 0, 0);
    border-radius: 30px 0 0 30px;
    color: #FFFFFF;
}
.has-switch .switch-off span.switch-left{
    background-color: $medium-gray;
}
.has-switch span.switch-right {
  border-radius: 0 30px 30px 0;
  background-color: $info-color;
  color: #ffffff;
  text-indent: 1px;
}
.has-switch .switch-off span.switch-right{
    background-color: $medium-gray;
}

.has-switch label {
    border-radius: 12px;
    float: left;
    height: 22px;
    margin: 1px -13px;
    padding: 0;
    position: relative;
    transition: all 0.25s ease-out 0s;
    vertical-align: middle;
    width: 22px;
    z-index: 100;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.has-switch .switch-on .fa-check:before{
    margin-left: 10px;
}
.has-switch:hover .switch-on label{
    margin: 1px -17px;
    width: 26px;
}
.has-switch:hover .switch-off label{
    margin: 1px -13px;
    width: 26px;
}

