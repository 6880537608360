/*           Labels & Progress-bar              */
.label{
    padding: 0.2em 0.6em 0.2em;
    border: 1px solid #999999;
    border-radius: 3px;
    color: #999999;
    background-color: #FFFFFF;
    font-weight: 500;
    font-size: 11px;
    display: inline-block;
    margin-bottom: 3px;
}
.label-primary{
    border-color: #3472F7;
    color: #3472F7;
}
.label-info{
    border-color: #2CA8FF;
    color: #2CA8FF;
}
.label-success{
    border-color: #05AE0E;
    color: #05AE0E;
}
.label-warning{
    border-color: #FF9500;
    color: #FF9500;
}
.label-danger{
    border-color: #FF3B30;
    color: #FF3B30;
}
.label-presential{
    border-color: #e47a75;
    color: #e47a75;
}
.label-nonpresential{
    border-color: #f3a43c;
    color: #f3a43c;
}
.label.label-fill{
    color: #FFFFFF;
}
.label-primary.label-fill, .progress-bar, .progress-bar-primary{
   background-color: #3472F7;
}
.label-info.label-fill, .progress-bar-info{
    background-color: #2CA8FF;
}
.label-success.label-fill, .progress-bar-success{
   background-color: #05AE0E;
}
.label-warning.label-fill, .progress-bar-warning{
   background-color: #FF9500;
}
.label-danger.label-fill, .progress-bar-danger{
   background-color: #FF3B30;
}
.label-presential.label-fill, .progress-bar-danger{
   background-color: #e47a75;
}
.label-nonpresential.label-fill, .progress-bar-danger{
   background-color: #f3a43c;
}
.label-default.label-fill{
   background-color: #999999;
}
