/*            Navigation menu                */
.nav-pills{
    background-color: $medium-gray;

    > li + li {
        margin-left: 0;
    }

    > li {
       background-color: #999999;
       border-radius: 0;
       border: 0 !important;
       color: #FFFFFF !important;
       font-size: 16px;
       padding:15px;
       text-align: center;
       text-transform: uppercase;
       width:50%;

       img{
        display:inline-block;
        margin:0 5px 5px 0;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        width:32px;
       }

    }

    > li.active{
        background-color: $warning-color;

        img{
          -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
        }

    }
}

@media (max-width: 768px){
  .nav-pills > li{
    width:100%;
  }
}
